import MovieModal from "./movieModal";

class Repertory {
    constructor(selector) {
        this.selector = selector;
        this.days = this.selector.querySelectorAll('[data-day]');
        this.table = this.selector.querySelector('[data-table]');
        this.loader = this.selector.querySelector('[data-loader]');

        this.currentDate = this.selector.getAttribute('data-current-date');

        this.schedule = {};

        this._changeDate = this._changeDate.bind(this);

        if (this.days.length > 0) {
            this.days.forEach(day => {
                day.addEventListener('click', this._changeDate);
            });
        }
    }
    _changeDate(e) {
        e.preventDefault();

        let newDate = null;
        if (!e.target.classList.contains('movies-days__single')) {
            let button = e.target.closest('.movies-days__single');
            newDate = button.getAttribute('data-day');
            this._setActiveDay(button);
        } else {
            newDate = e.target.getAttribute('data-day');
            this._setActiveDay(e.target);
        }

        if (newDate !== this.currentDate) {
            this.currentDate = newDate;
            this._loadSchedule();
        }
    }
    _loadSchedule() {
        if (this.schedule.hasOwnProperty(this.currentDate)) {
            this.table.innerHTML = '';
            this._generatePosts(this.schedule[this.currentDate]);
        } else {
            this._showLoaderAnimation();
            this.isLoading = true;
            this.table.innerHTML = '';
            this._getFromAPI().then(response => {
                if(response.length > 0){
                    this.schedule[this.currentDate] = response;
                    this._generatePosts(response);
                } else {
                    this._noResults();
                }
            }).catch(error => {
                this._handleError(error);
            });
        }
    }

    _getFromAPI(){
        let url = '/wp-json/netivo/v1/schedule?date=' + this.currentDate;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _showLoaderAnimation() {
        this.loader.classList.add('loader--shown');
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove('loader--shown');
    }
    _noResults(){
            let text = document.createElement('p');
            text.innerHTML = 'Brak filmów spełniających kryteria.';
            this.table.parentNode.classList.add('movies-table--not-found');
            this.table.appendChild(text);
            this._hideLoaderAnimation();
    }
    _handleError(error){
        let text = document.createElement('p');
        text.innerHTML = 'Brak filmów spełniających kryteria.';
        this.table.parentNode.classList.add('movies-table--not-found');
        this.table.appendChild(text);
        this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _generatePosts(posts) {
        if (this.table.parentNode.classList.contains('movies-table--not-found')) {
            this.table.parentNode.classList.remove('movies-table--not-found');
        }
        posts.forEach((post, key) => {
            let postElement = this._generatePost(post);
            this.table.append(postElement);
        });
        this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _generatePost(post) {
        let dayNames = [
            'Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'
        ];
        let monthNames = [
            'Stycznia', 'Lutego', 'Marca', 'Kwietnia', 'Maja', 'Czerwca',
            'Lipca', 'Sierpnia', 'Września', 'Października', 'Listopada', 'Grudnia'
        ];

        // console.log(post);

        let row = document.createElement('div');
        row.classList.add('movies-table__row');

        let thumbnail_cntr = document.createElement('a');
        thumbnail_cntr.classList.add('movies-table__single-thumbnail-cntr');
        thumbnail_cntr.setAttribute('href', post.link);

        let thumbnail = document.createElement('img');
        thumbnail.classList.add('movies-table__single-thumbnail');
        thumbnail.setAttribute('alt', post.title.rendered);
        thumbnail.setAttribute('src', post.image);

        thumbnail_cntr.appendChild(thumbnail);

        let data_cntr = document.createElement('div');
        data_cntr.classList.add('movies-table__single-data');

        let title = document.createElement('div');
        title.classList.add('title', 'title--size-24', 'title--normal', 'title--no-shadow');

        let title_link = document.createElement('a');
        title_link.setAttribute('href', post.link);
        title_link.innerText = post.title.raw;

        title.appendChild(title_link);

        let label_cntr = document.createElement('div');
        label_cntr.classList.add('movies-table__single-tags');

        if (post.meta._movie_label !== null && post.meta._movie_label !== '') {
            let label = document.createElement('div');
            label.classList.add('movies-table__single-tag');
            if (post.meta._movie_label_color !== null && post.meta._movie_label_color !== '') {
                label.style.backgroundColor = '#' + post.meta._movie_label_color;
            }
            label.innerText = post.meta._movie_label;
            label_cntr.appendChild(label);
        }

        let categories_cntr = null;
        let categories = '';
        if (post.categories !== null && post.categories.length > 0 ) {
            categories_cntr = document.createElement('div');
            categories_cntr.classList.add('movie-table__single-categories');
            categories = post.categories.join(', ');
            categories_cntr.innerText = categories;
        }

        let stats_cntr = document.createElement('ul');
        stats_cntr.classList.add('movies-table__single-stats');

        let duration_cntr = document.createElement('li');
        duration_cntr.classList.add('movies-table__single-stats-item');

        let duration_b = document.createElement('b');
        duration_b.innerText = 'Czas trwania: ';

        let duration_value = document.createElement('span');
        duration_value.innerText = post.meta._movie_duration;

        duration_cntr.appendChild(duration_b);
        duration_cntr.appendChild(duration_value);
        stats_cntr.appendChild(duration_cntr);

        let age_cntr = document.createElement('li');
        age_cntr.classList.add('movies-table__single-stats-item');

        let age_b = document.createElement('b');
        age_b.innerText = 'Od lat: ';

        let age_value = document.createElement('span');
        age_value.innerText = post.meta._movie_age;

        age_cntr.appendChild(age_b);
        age_cntr.appendChild(age_value);
        stats_cntr.appendChild(age_cntr);

        let production_cntr = document.createElement('li');
        production_cntr.classList.add('movies-table__single-stats-item');

        let production_b = document.createElement('b');
        production_b.innerText = 'Produkcja: ';

        let production_value = document.createElement('span');
        production_value.innerText = post.meta._movie_production + ' (' + post.meta._movie_year_production + ')';

        production_cntr.appendChild(production_b);
        production_cntr.appendChild(production_value);
        stats_cntr.appendChild(production_cntr);

        data_cntr.appendChild(title);
        data_cntr.appendChild(label_cntr);
        if (categories_cntr !== null) {
            data_cntr.appendChild(categories_cntr);
        }
        data_cntr.appendChild(stats_cntr);

        let repertory = document.createElement('div');
        repertory.classList.add('movies-table__single-repertory');

        let schedule = post.schedule;
        if (schedule !== null) {
            schedule.forEach(type => {
                let single_repertory_cntr = document.createElement('div');
                single_repertory_cntr.classList.add('movies-table__single-repertory-cntr');

                let single_repertory_title = document.createElement('h3');
                single_repertory_title.innerText = type.type;
                single_repertory_cntr.appendChild(single_repertory_title)

                type.hours.forEach(hour => {
                    let current_date = new Date(this.currentDate);
                    let day = current_date.getDay();
                    let seance_date_string = hour.hour + ' ' + dayNames[day] + ' ' + current_date.getDate() + ' ' + monthNames[current_date.getMonth()] + ' ' + current_date.getFullYear();
                    let button = document.createElement('a');
                    button.setAttribute('href', '');
                    button.classList.add('js-movie-modal');
                    button.innerText = hour.hour;
                    button.setAttribute('data-event-id', hour.event_id);
                    button.setAttribute('data-movie-id', post.id);
                    button.setAttribute('data-categories', categories);
                    button.setAttribute('data-duration', post.meta._movie_duration);
                    button.setAttribute('data-age', post.meta._movie_age);
                    button.setAttribute('data-production', post.meta._movie_production);
                    button.setAttribute('data-thumbnail', post.image);
                    button.setAttribute('data-year-production', post.meta._movie_year_production);
                    button.setAttribute('data-scenario', post.meta._movie_scenario);
                    button.setAttribute('data-direction', post.meta._movie_direction);
                    button.setAttribute('data-permalink', post.link);
                    button.setAttribute('data-title', post.title.raw);
                    button.setAttribute('data-seance-date', seance_date_string);
                    button.setAttribute('data-type', type.type);

                    new MovieModal(button);
                    single_repertory_cntr.appendChild(button);
                });
                repertory.appendChild(single_repertory_cntr);
            });
        }

        row.appendChild(thumbnail_cntr);
        row.appendChild(data_cntr);
        row.appendChild(repertory);

        return row;
    }

    _setActiveDay(element) {
        this.days.forEach(day => {
            day.classList.remove('movies-days__single--active');
        });
        element.classList.add('movies-days__single--active');
    }
}
export default Repertory;