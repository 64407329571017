class Movies {
    constructor(selector) {
        this.selector = selector;
        this.postContainer = this.selector.querySelector('[data-container]');
        this.loader = this.selector.querySelector('[data-loader]');
        this.button = this.selector.querySelector('[data-button]');
        this.filterForm = this.selector.querySelector('[data-filter-form]');

        this.isLoading = false;
        this.lastPage = false;

        this._loadAttributes();

        if (this.loadOnInit) {
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }

        if (this.button !== null) {
            this._nextPageEvent = this._nextPageEvent.bind(this);
            this.button.addEventListener('click', this._nextPageEvent);
        }

        if (this.filterForm !== null) {
            let inputs = this.filterForm.querySelectorAll('input[type="checkbox"]');
            if (inputs !== null) {
                inputs.forEach(input => {
                    input.addEventListener('change', e => {
                        this.page = 1;
                        this._getFormData();
                        this.postContainer.innerHTML = '';
                        this._loadPosts();
                    });
                });
            }
        }
    }

    _loadAttributes(){
        let types = this.selector.getAttribute('data-type');
        if(types !== null && types !== '') {
            this.type = types;
        }

        let perPage = this.selector.getAttribute('data-per-page');
        if(perPage !== null && perPage !== ''){
            this.perPage = parseInt(perPage);
        } else this.perPage = 10;

        if (window.innerWidth <= 991) {
            this.perPage = 4;
        } else if (window.innerWidth <= 650) {
            this.perPage = 3;
        } else if (window.innerWidth <= 500) {
            this.perPage = 4;
        }

        let loadOnInit = this.selector.getAttribute('data-load-on-init');
        if(loadOnInit !== null && loadOnInit !== ''){
            this.loadOnInit = (loadOnInit === 'true');
        } else this.loadOnInit = false;

        let page = this.selector.getAttribute('data-page');
        if(page !== null && page !== ''){
            this.page = parseInt(page);
        } else this.page = 1;

        let categories = this.selector.getAttribute('data-category');
        if(categories !== null && categories !== ''){
            this.categories = categories.split(',');
        } else this.categories = [];

        let max = this.selector.getAttribute('data-max');
        if (max !== null && max !== '') {
            this.max = max;
        }
    }

    _nextPageEvent(event){
        event.preventDefault();
        if(!this.lastPage) {
            this._nextPage();
        }
    }

    _nextPage(){
        this.page++;
        this._loadPosts();
    }

    _getFromAPI(){
        let queryString = this._buildQuery();
        let url = '/wp-json/wp/v2/movie?' + queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _buildQuery(){
        let query = [
            'per_page=' + this.perPage,
            'page=' + this.page
        ];

        if(this.categories.length > 0){
            query.push('movie_tags=' + this.categories.join(','));
        }

        query.push('_embed');

        if (this.type !== null) {
            query.push('_movie_status=' + this.type);
        }

        return query.join('&');
    }

    _loadPosts() {
        // this._showLoaderAnimation();
        this.isLoading = true;
        this._getFromAPI().then(response => {
            if(response.length > 0){
                this._generatePosts(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }
    _generatePosts(posts){
        if (this.postContainer.classList.contains('movies-grid--not-found')) {
            this.postContainer.classList.remove('movies-grid--not-found');
        }
        posts.forEach((post, key) => {
            let postElement = this._generateMovieBlock(post);
            this.postContainer.append(postElement);
        });
        let current_count_posts = this.postContainer.querySelectorAll('.movies-grid__item').length;
        if (current_count_posts >= this.max) {
            this.button.style.display = 'none';
        }

        if (this.type === 'na-ekranie') {
            let categories = this.filterForm;
            let is_cats_empty = true;
            categories.querySelectorAll('input[type="checkbox"]').forEach(item => {
                if (item.checked) {
                    is_cats_empty = false;
                }
            });
            if (is_cats_empty === false) {
                this.button.style.display = 'none';
            } else {
                let current_count_posts = this.postContainer.querySelectorAll('.movies-grid__item').length;
                if (current_count_posts < this.max) {
                    this.button.style.display = 'inline-block';
                }
            }
        }

        this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _showLoaderAnimation() {
        this.loader.classList.add('loader--shown');
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove('loader--shown');
    }
    _noResults(){
        if(this.page === 1){
            let text = document.createElement('p');
            text.innerHTML = 'Brak filmów spełniających kryteria.';
            this.postContainer.classList.add('movies-grid--not-found');
            this.postContainer.appendChild(text);
            this._hideLoaderAnimation();
        } else {
            this.lastPage = true;
            this._hideLoadMoreButton();
        }
    }
    _handleError(error){
        if (error.message === 'rest_post_invalid_page_number') {
            this.lastPage = true;
            this._hideLoadMoreButton();
        } else {
            let text = document.createElement('p');
            text.innerHTML = 'Wystąpił błąd poczas ładowania filmu.';
            this.postContainer.classList.add('movies-grid--not-found');
            this.postContainer.appendChild(text);
            this._hideLoaderAnimation();
            this.isLoading = false;
        }
    }
    _generateMovieBlock(post) {
        let item = document.createElement('a');
        item.classList.add('movies-grid__item');
        item.setAttribute('href', post.link);

        let image_container = document.createElement('div');
        image_container.classList.add('movies-grid__item-image-cntr');

        let image_url = null;
        if (typeof post._embedded["wp:featuredmedia"] !== 'undefined') {
            let media = post._embedded["wp:featuredmedia"][0];
            if (typeof media.media_details.sizes["movie-poster"] !== 'undefined') {
                image_url = media.media_details.sizes["movie-poster"].source_url;
            } else image_url = media.source_url;
        }
        let image = null;
        if (image_url !== null) {
            image = document.createElement('img');
            image.classList.add('movies-grid__item-image');
            image.setAttribute('alt', post.title.rendered);
            image.setAttribute('src', image_url);
        }

        let buttons_container = document.createElement('div');
        buttons_container.classList.add('movies-grid__item-buttons');

        let button = document.createElement('span');
        button.innerText = 'O filmie';

        let title = document.createElement('div');
        title.classList.add('movies-grid__item-title');
        title.innerHTML = post.title.rendered;

        buttons_container.appendChild(button);

        if (image !== null) {
            image_container.appendChild(image);
        }
        image_container.appendChild(buttons_container);

        item.appendChild(image_container);
        item.appendChild(title);

        return item;
    }
    _hideLoadMoreButton() {
        this.button.style.display = 'none';
    }
    _getFormData() {
        let categories = this.filterForm;
        this.categories = [];
        categories.querySelectorAll('input[type="checkbox"]').forEach(item => {
            if (item.checked) {
                this.categories.push(item.value);
            }
        });
    }
}
export default Movies;